export const WALLET_INSTALLED = "WALLET_INSTALLED";
export const CONNECT_WALLET = "CONNECT_WALLET";
export const GET_ACCOUNTS = "GET_ACCOUNTS";
export const GET_SHORT_ACCOUNTS = "GET_SHORT_ACCOUNTS";
export const GET_CURENT_CHAIN = "GET_CURENT_CHAIN";
export const GET_CONFIGURED_CHAIN = "GET_CONFIGURED_CHAIN";
export const GET_CURENT_NETWORK = "GET_CURENT_NETWORK";
export const GET_CONFIGURED_NETWORK = "GET_CONFIGURED_NETWORK";
export const CORRECT_NETWORK = "CORRECT_NETWORK";
export const ACCOUNT_CONNECTED = "ACCOUNT_CONNECTED";
