export const articles = [
    {
        id: 1,
        title: 'One: Lorem ipsum dolor sit amet',
        image: '/images/austin-distel-rxpThOwuVgE-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 2,
        title: 'Two: Lorem ipsum dolor sit amet',
        image: '/images/christina-wocintechchat-com-swi1DGRCshQ-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 3,
        title: 'Three: Lorem ipsum dolor sit amet',
        image: '/images/clay-banks-3IFYE6UHFBo-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 4,
        title: 'Four: Lorem ipsum dolor sit amet',
        image: '/images/espolon-tequila-_V8ByseVGpk-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 5,
        title: 'Five: Lorem ipsum dolor sit amet',
        image: '/images/hansjorg-keller-p7av1ZhKGBQ-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 6,
        title: 'Six: Lorem ipsum dolor sit amet',
        image: '/images/luca-severin-zMecixx58Uw-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 7,
        title: 'Seven: Lorem ipsum dolor sit amet',
        image: '/images/nicolas-tissot-2XSpU-6siwk-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 8,
        title: 'Eight: Lorem ipsum dolor sit amet',
        image: '/images/nrd-D6Tu_L3chLE-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 9,
        title: 'Nine: Lorem ipsum dolor sit amet',
        image: '/images/piermanuele-sberni-2mMMEVUdXcs-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 10,
        title: 'Ten: Lorem ipsum dolor sit amet',
        image: '/images/rod-long-f6PNAO4Kn18-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 11,
        title: 'Eleven: Lorem ipsum dolor sit amet',
        image: '/images/sean-pollock-PhYq704ffdA-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    },
    {
        id: 12,
        title: 'Twelve: Lorem ipsum dolor sit amet',
        image: '/images/brittany-nailon-UVuTceEtSpg-unsplash.jpg',
        summary: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin...',
        content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin dictum quam a finibus facilisis. Vivamus vitae commodo mi. Pellentesque mollis arcu eu nibh facilisis, at convallis nisi pellentesque. Pellentesque accumsan purus eu mollis aliquet. Sed rutrum, nibh et efficitur ultrices, erat diam mollis ipsum, ut varius nulla lacus non tortor. Duis sagittis quam dolor, at porta ligula venenatis a. Nam gravida, erat quis venenatis vestibulum, nisi nisl fringilla urna, nec placerat libero eros id ipsum. Duis et hendrerit ex, a hendrerit libero. Praesent mollis est eget ex euismod pulvinar. Quisque luctus at odio eget condimentum.'
    }
]